import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvoiceModel } from '../../models/invoice.model';
import { RootState } from '../store';

type InvoicesState = {
  value: InvoiceModel[];
};

const initialState: InvoicesState = { value: [] };

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoices: (state, action: PayloadAction<InvoiceModel[]>) => {
      state.value = action.payload;
    },
  },
});

export const { setInvoices } = invoicesSlice.actions;
export const selectInvoices = (state: RootState) => state.invoices.value;

export default invoicesSlice.reducer;
