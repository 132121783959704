import { FC } from 'react';

const LoadingScreen: FC = () => {
  return (
    <div className="grid h-screen w-screen place-items-center">
      <img width={200} src="/images/loading.gif" alt="loading" />
    </div>
  );
};

export default LoadingScreen;
